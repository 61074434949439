.navbar-brand svg {
    height: 65px;
}

header {
    &.navbar {
        background-color: #123256;
        height: 75px;

        .header-filters .form-floating {
            color: #fff;

            select {
                border: 0;
                color: #fff;
                position: relative;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                border: none;
                background: #0f2236
                    url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
                    no-repeat;
                background-position: right 5px top 50%;
            }
        }
    }
    &.page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
        padding: 0.5rem 1rem;


        h1 {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 0;

            @media screen and (max-width: 1200px) {
                padding: 0.5rem 0.5rem 0.5rem 2.5rem;
            }
        }

        .wow-header-status {
            display: flex;
            flex-direction: column;
            line-height: 1;
            gap: 0.25rem;
            border-left: 1px solid #727272;
            padding-left: 0.5rem;
            margin-left: 0.5rem;
            font-size: 13px;

            span {
                color: #8bb8a2;
                font-weight: 700;
            }
        }
    }
}

main button.navbar-toggler{
    width: 3rem;
    height: 3rem;
    color: var(--bs-white);
    position: fixed;
    left: 0.25rem;
    top: 0.75rem;
    z-index: 1000;

    &.collapsed{
        color: var(--bs-primary);
        top: 0;
    }

    svg{
        width: 3rem;
        height: 3rem;
    }
}

// main[role=main]{
//     min-height: calc(100vh - 75px);
//     > form{
//         min-height: 100%;
//     }
// }

section.page-content {
    padding: 1rem;
}

/* Tables */
table {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    &.table {
        thead {
            background: #e1ebfb;
            border-bottom: 2px solid var(--bs-primary);
            color: var(--bs-primary);

            tr:last-child {
                background: linear-gradient(
                    0deg,
                    #f2f2f2 0%,
                    rgb(255 255 255) 100%
                );
                border: 1px solid #eeeeee;

                @media screen and (max-width: 900px) {
                    display: none;
                }
            }

            tr td .flatpickr {
                position: relative;

                .form-control {
                    padding-right: 2rem;
                }
                button[data-clear] {
                    position: absolute;
                    top: 6px;
                    right: 4px;
                    border-radius: 50%;
                    height: 25px;
                    width: 25px;
                    line-height: 1;
                    padding: 0;
                }
            }
        }

        // td.td-actions > a {
        //     text-decoration: none;
        // }

        td > form {
            display: inline-flex;
        }

        &.table-striped > tbody > tr:nth-of-type(odd) > * {
            background-color: #f3f8ff;
            box-shadow: none !important;
        }

        &.dataTable {
            thead > tr > th.sorting {
                &:before {
                    content: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 4.5L4 1L0.5 4.5' stroke='%23123256' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
                &:after {
                    content: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 1L4 4.5L0.5 1' stroke='%23123256' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }

            th,
            td {
                vertical-align: middle;
                line-height: 1.2;
            }
        }

        > :not(caption) > * > * {
            border-bottom-width: 2px;
        }
    }
}

/* Stepper */
ul.stepper {
    counter-reset: currentStep 00;
    padding-left: 0;
    list-style: none;
    margin-bottom: 1rem;
    display: flex;

    li {
        display: flex;
        flex-grow: 1;

        button {
            display: flex;
            flex-grow: 1;
            background: none;
            border: none;

            &.active:before {
                background-color: #8bb8a2;
                content: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                border: 0;
            }

            &:before {
                content: counter(currentStep);
                counter-increment: currentStep;
                border: 1px solid #333;
                border-radius: 6px;
                margin-right: 0.5rem;
                height: 24px;
                width: 24px;
                display: inline-flex;
                justify-content: center;
            }

            &:after {
                width: 0;
                white-space: nowrap;
                content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
                display: flex;
                overflow: hidden;
                flex-grow: 1;
                margin-right: 1rem;
            }
        }
    }
}

/* sidebarMenu */
#sidebarMenu {
    width: 100%;
    max-width: 200px;
    background-color: #0f2236;
    min-height: 100vh;

    .nav-link {
        padding: 0.75rem;
        color: #888888;

        &.active,
        &:hover,
        &:focus {
            color: #f3f8ff;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.375rem;

            &:nth-child(2){
                float: right;
                margin-right: 0;
            }
        }
    }

    li#nav-item-configurations > a:before {
        content: "";
        width: 100%;
        height: 1px;
        background: #888;
        display: block;
        margin-bottom: 1.5rem;
    }
    @media screen and (max-width: 1200px) {
        position: fixed;
        z-index: 1000;
        width: 100vw;
        max-width: 100vh;
    }
}

.sidebarRight {
    background-color: #e1ebfb;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: auto;
    max-height: calc(100vh - 42px);

    .nav-tabs {
        border-bottom: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1;

        .nav-link {
            background: #aebdd2;
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #123256;
            font-size: 0.8125rem;
            font-weight: 700;
            line-height: 2;

            &.active {
                background: #e1ebfb;
                border-bottom: transparent;
            }
        }
    }
    .tab-content {
        height: 100%;
    }
    .tab-pane {
        height: 100%;
        position: relative;

        &#comments > section {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
    .comments {
        height: 100%;
        padding: 1rem 0.5rem;
        overflow: auto;
        // max-height: calc(100vh - 42px - 53px);

        .comment-block {
            background-color: #fff;
            border-radius: 6px;
            padding: 0.5rem;
            margin-bottom: 1rem;
            margin-right: 1rem;

            &.my-comment {
                background-color: #cbd9ee;
                margin-left: 1rem;
                margin-right: 0;
            }

            .user {
                font-size: 0.875rem;
                font-weight: 700;
                color: #123256;
            }
            .comment {
                font-size: 0.8125rem;
            }
            .datetime {
                font-size: 0.625rem;
                color: #727272;
            }
            .is-read {
                float: right;
            }
        }
    }
    .new-comment {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background-color: #cbd9ee;
        padding: 0.5rem;

        .new-comment-block{
            display: flex;
            gap: 0.5rem;
            flex-direction: row;
            flex-wrap: nowrap;

            .new-comment-input{
                position: relative;
                flex-grow: 1;

                input{
                    padding-right: 32px;
                }

                .actions{
                    position: absolute;
                    right: 4px;
                    top: 0;
                    height: 37px;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    justify-content: center;
                    align-items: center;

                    svg{
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            button{
                width: 36px;
                height: 36px;
                padding: 3px;

                svg{
                    width: 100%;
                    height: 100%;
                }
            }
            input[type="file"]{
                display: none;
            }
        }

    }

    .preview {
        display: flex;
        gap: 0.5rem;
        justify-content: start;
        align-items: start;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;

        .preview-item{
            height: 75px;
            min-width: 75px;
            max-width: calc((100% / 3) - 0.5em);
            background-color: rgb(255 255 255 / 50%);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            position: relative;

            a:not([download]){
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
            .action{
                position: absolute;
                top: 0;
                right: 0;
                padding: 3px;
                cursor: pointer;

                &:hover{
                    background: #cbd9ee;
                    border-radius: 4px;
                }

                svg{
                    width: 20px;
                    height: 20px;
                }
            }

            img{
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }

    }

    .history {
        height: 100%;
        padding: 1rem;

        .history-block {
            background-color: #fff;
            border-radius: 6px;
            padding: 1rem;
            margin-bottom: 0.5rem;
            cursor: pointer;

            &.history-even {
                background-color: #cbd9ee;
            }

            .user {
                font-size: 0.875rem;
                font-weight: 700;
                color: #123256;
            }
            .changes {
                font-size: 0.6125rem;
                background-color: #a5aebc;
                border-radius: 3px;
                white-space: nowrap;
                padding: 2px;
                margin: -2px;
            }
            .datetime {
                margin-top: 2px;
                font-size: 0.625rem;
                color: #727272;
            }
            .changes-block {
                padding-top: 5px;
                padding-bottom: 5px;
                .change {
                    .label-added {
                        background-color: #4cc591;
                        border-radius: 3px;
                        font-size: 0.675rem;
                        font-weight: 400;
                        padding: 2px;
                        color: #ddd;
                    }
                    .label-updated {
                        background-color: #137b4e;
                        border-radius: 3px;
                        font-size: 0.675rem;
                        font-weight: 400;
                        padding: 2px;
                        color: #ddd;
                    }
                    .label-removed {
                        background-color: #d2590e;
                        border-radius: 3px;
                        font-size: 0.675rem;
                        font-weight: 400;
                        padding: 2px;
                        color: #ddd;
                    }
                    .label-key {
                        font-size: 0.675rem;
                        font-weight: 400;
                        color: #123256;
                    }
                    .label-value {
                        font-size: 0.575rem;
                        font-weight: 500;
                        color: #7e8082;
                    }
                }
            }
        }
    }
}

/* KPIS */
.kpis {
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
    flex-wrap: wrap;

    .kpi-section {
        flex: 1 1 0;

        .kpi-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            background-color: #cbd9ee;
            border-bottom: 2px solid #123256;
            padding: 0.75rem;
            border-radius: 10px 10px 0 0;

            .kpi-title {
                color: #123256;
                font-size: 14px;
                font-weight: 700;
            }

            .kpi-header-total {
                display: flex;
                gap: 1rem;

                div {
                    display: flex;
                    flex-direction: column;
                    line-height: 1.2;
                }
                div:last-child {
                    font-weight: 700;
                }
            }
        }

        .kpi-body {
            display: flex;
            gap: 1rem;
            padding: 1rem;
            background: #fff;
            border-radius: 0 0 10px 10px;

            .kpi {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: #e1ebfb;
                border-radius: 0.5rem;
                box-shadow: 4px 4px 10px rgb(211 211 211 / 25%);
                padding: 0.75rem;
                color: #123256;
                flex: 1 1 0;
                width: 0;
                border: 2px solid #e1ebfb;
                cursor: pointer;

                &.total {
                    background-color: #e1ebfb;
                }
                &:hover{
                    border-color: #123256;
                }

                &.kpi-header-selected {
                    background-color: #123256;
                    color: #ffffff;
                }


                h5 {
                    font-size: 1.25rem;
                    margin-bottom: 0;
                }

                p {
                    font-weight: 700;
                    font-size: 0.8125rem;
                    margin-bottom: 0;
                }

                .kpi-footer {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    margin-top: 1rem;
                    color: #727272;

                    div {
                        flex-direction: column;
                        display: flex;
                    }
                }
            }
        }

        &.inactive {
            .kpi-header,
            .kpi-body .kpi {
                background-color: #f1f1f1;
            }
        }
    }

    &.kpis-v2 .kpi-section {
        .kpi-body{
            flex-direction: column;
            background-color: #e1ebfb;
            border-radius: 0.5rem;
            padding: 1rem 0.75rem;
            color: #123256;
        }

        &.inactive .kpi-body {
            background-color: #f1f1f1;
        }


        .kpi{
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            background-color: inherit;
            box-shadow: none;
        }
    }
}

/* Cards */
.card {
    .card-header {
        border-top: 4px solid #8bb8a2;
        border-bottom: none;
        font-weight: 600;
        font-size: 1rem;
    }
    .card-footer {
        padding: 0;

        > .btn-group {
            width: 100%;
        }
        > .btn {
            width: 100%;
        }
    }
    .card-subtitle {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    &.dashboard {
        border: 0;
        border-radius: 10px;
        .card-header {
            background-color: #e1ebfb;
            border-top: 0;
            border-bottom: 2px solid #123256;
            padding: 1rem;
        }
        .card-body {
            border: 1px solid #eeeeee;
            padding: 1rem;

            .kpi-block {
                margin-bottom: 1rem;

                .kpi-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .kpi-indicator {
                        line-height: 1.75rem;
                        color: #34b46a;

                        &.kpi-indicator-negative {
                            color: #e63955;
                        }
                    }

                    h5 {
                        margin-bottom: 0;
                        font-weight: 700;
                        font-size: 1rem;
                        color: #888888;
                    }
                }
                .kpi-dashboard {
                    font-family: "Inter";
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    gap: 1rem;

                    .kpi {
                        display: flex;
                        flex-direction: column;

                        .kpi-total {
                            display: flex;
                            gap: 0.5rem;
                            align-items: baseline;
                            font-size: 1.375rem;
                            color: #123256;
                            line-height: 1.875rem;

                            .kpi-indicator {
                                font-size: 1rem;
                                line-height: 1.75rem;
                                color: #34b46a;

                                &.kpi-indicator-negative {
                                    color: #e63955;
                                }
                            }
                        }
                        small {
                            color: #aaaaaa;
                            font-size: 0.8125rem;
                        }

                        &.previous .kpi-total {
                            color: #aaaaaa;
                        }
                    }
                }
            }
        }
    }
}

/* Inputs */
.form-control {
    background: #fff;
    border-color: #727272;
}
.form-select {
    background-color: #fff;
    border-color: #727272;
}

.form-floating {
    label {
        color: #727272;
    }

    > textarea.form-control {
        min-height: calc(3.5rem + 2px);
        height: inherit;
    }

    /* Flatpickr */
    > .flatpickr-container {
        .form-control {
            padding: 1rem 0.75rem;
            height: calc(3.5rem + 2px);
            line-height: 1.25;
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
        & ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
    }

    /* Select2 */
    .select2 {
        .select2-selection {
            padding: 1rem 0.75rem;
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
            min-height: calc(3.5rem + 2px);
            line-height: 1.25;
            border-color: #727272;

            .select2-search {
                display: inline-flex;

                .select2-search__field {
                    height: 20px;
                    margin: 0;
                    width: inherit !important;
                }
            }
            &.select2-selection--multiple .select2-selection__rendered {
                padding: 0;

                .select2-selection__choice {
                    margin-right: 0.5rem;
                    margin-left: 0;
                }
            }
        }
    }

    &.wow-search-autocomplete {
        .form-control input {
            border: 0;
            outline: 0;
        }

        .remove-selection {
            position: absolute;
            right: 0.5rem;
            top: 1.625rem;
            font-weight: 600;
            font-size: 1rem;
            border: 1px solid #212529;
            line-height: 1;
            width: 1.625rem;
            height: 1.625rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1rem;
            cursor: pointer;
        }
    }
}
ul.wow-search-autocomplete-results {
    width: 100%;
    padding: 0;
    position: absolute;
    margin: -5px 0 0 0;
    background: #fff;
    list-style: none;
    cursor: pointer;
    border: 1px solid #727272;
    box-shadow: 4px 4px 10px rgb(211 211 211 / 25%);
    border-radius: 0 0 4px 4px;
    z-index: 10;

    li {
        padding: 0.25rem 1rem;

        &:hover {
            background-color: #e1ebfb;
        }
    }
}

/* Login Card */
.card-login {
    background-color: #123256;
    border-radius: 10px;
    padding: 2rem;
    color: #fff;
}

#pageLogin {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
}


.property-upload-header {
    display: flex;
    gap: 3.125rem;
    justify-content: space-between;
    align-items: flex-start;
}

.doc-upload {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .doc-upload-header {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }

    .doc-upload-body {
        border: 1px dashed #123256;
        border-radius: 5px;
        padding: 0.75rem;
        background-color: #f3f8ff;
        word-break: break-word;
        position: relative;
        cursor: pointer;

        .doc-upload-area {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .doc-upload-area-body {
                display: flex;
                flex-direction: column;

                .doc-upload-title {
                    margin-bottom: 0;
                }
                small {
                    color: #727272;
                    font-size: 10px;
                }
            }
        }

        .wow-file-actions {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #f3f8ff;
            border: 1px solid #333;
            border-radius: 20px;
            padding: 0 0.5rem;
            display: flex;
            gap: 0.5rem;
        }
    }
}

.property-upload-section{
    position: relative;

    > .doc-upload{
        flex-direction: column-reverse;
        padding-top: 1rem;

        > .doc-upload{
            position: absolute;
            top: 2px;
            right: 90px;

            .doc-upload-body{
                padding: 0;
                border: 0;
                background: transparent;

                .doc-upload-area-body{
                    display: none;
                }
            }
        }
        .doc-upload-modal{
            position: absolute;
            top: 0px;
            right: 120px;
        }
    }

    &.property-upload-section-true > .doc-upload{
        > .doc-upload{
            right: 125px;
        }
        .doc-upload-modal{
            right: 150px;
        }
    }
}

.comments_counter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;

    &:before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32' fill='currentColor'%3E%3Cpath d='M16 30c-0.003 0-0.007 0-0.012 0-2.486 0-4.821-0.648-6.846-1.783l0.071 0.036-5.76 1.080c-0.040 0.009-0.086 0.014-0.134 0.014-0.368 0-0.667-0.298-0.667-0.667 0-0.047 0.005-0.093 0.014-0.138l-0.001 0.004 1.067-5.76c-1.104-1.956-1.755-4.296-1.755-6.787 0-7.732 6.268-14 14-14s14 6.268 14 14c0 7.724-6.256 13.988-13.977 14h-0.001zM9.333 26.88c0.123 0.004 0.237 0.038 0.337 0.095l-0.004-0.002c1.814 1.064 3.994 1.692 6.321 1.692 6.988 0 12.653-5.665 12.653-12.653s-5.665-12.653-12.653-12.653c-6.988 0-12.653 5.665-12.653 12.653 0 2.327 0.628 4.508 1.724 6.381l-0.032-0.060c0.057 0.096 0.090 0.212 0.090 0.335 0 0.042-0.004 0.082-0.011 0.122l0.001-0.004-0.933 5.040 5.040-0.933z'%3E%3C/path%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        position: absolute;
        color: #585858;
    }

    &.unread {
        color: red;
    }
}

.sidebarRightResume {
    padding: 1rem;
    border-bottom: 1px solid #123256;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .sidebarRightResumeItem {
        display: flex;
        flex-direction: column;

        label {
            color: #123256;
            font-weight: 700;
        }
    }
}

.btn-check:checked + .btn.btn-outline-secondary,
:not(.btn-check) + .btn.btn-outline-secondary:active,
.btn.btn-secondary {
    color: #fff;
}

.tooltip.b-tooltip {
    opacity: 1;
}

svg[title] {
    cursor: help;
}

.modal {
    .modal-backdrop {
        opacity: 0.5;
    }
    .modal-xl.modal-dialog {
        max-width: 90vw;
    }
}

.template-block {
    border-bottom: 2px solid #d5d5d5;

    &:hover {
        border-bottom-color: #8bb8a2;

        img {
            box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
        }
    }

    img {
        object-fit: cover;
        object-position: top;
        max-height: 400px;
        width: 100%;
        margin-bottom: 0.5rem;
        border-radius: 6px;
    }

    .template-block-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
            font-size: 1rem;
            font-family: "Inter";
            font-weight: 500;
            color: #123256;
        }
    }
}

.marketing-template-categories-list {
    .list-group .list-group-item:hover {
        background-color: #ccc;
        cursor: pointer;
    }
    .wow-card-marketing-cat .card{
        display: flex;
        align-items: stretch;

        > div:not(.card-body){
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}

svg.property-info-helper{
    width: 1.125rem;
    cursor: help;
}

.marketing-template-categories-list{
    .wow-marketing-categories{
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;

        li{
            background-color: #e1ebfb;
            border-radius: 0.5rem;
            box-shadow: 4px 4px 10px rgba(211, 211, 211, 0.25);
            padding: 1rem;
            color: #123256;
            cursor: pointer;
            border: 2px solid transparent;

            &:hover{
                border-color: #123256;
            }
            &.selected{
                background-color: #123256;
                border-color: #123256;
                color: #fff;
            }
        }
    }
    .wow-marketing-templates{
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;

        li{
            border-radius: 0.5rem;
            box-shadow: 4px 4px 10px rgba(211, 211, 211, 0.25);
            color: #123256;
            cursor: pointer;
            border: 2px solid #e1ebfb;
            overflow: hidden;

            .card-img{
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:hover,
            &.selected{
                border-color: #123256;
            }
        }
    }
}
.wow-documents-grid {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, auto);
    grid-gap: 1rem;

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(10, auto);
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(100, auto);
    }
}
