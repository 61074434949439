// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Inter', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary: #0F2236;
$secondary: #8BB8A2;

// Cards
$card-cap-bg: transparent;

$badge-font-size: .875em;
$badge-font-weight: 400;

.badge{
    line-height: $badge-font-size !important;
}
// $badge-color:                       $white;
// $badge-padding-y:                   .35em;
// $badge-padding-x:                   .65em;
// $badge-border-radius:               $border-radius;
